import ApplicationsProfileImage from "@components/svgs/applicationsProfileImage";
import SmallAvatar from "@assets/avatar6.png";
import GhanaFlag from "@components/svgs/flags/ghana";
import Badge from "@components/badges/Badge";
import LinkButton from "@components/ButtonV2/LinkButton";

const requirements = [
  "Bachelor’s degree in Finance, Accounting, Economics, or a related field from a recognized Ghanaian university.",
  "Proficiency in using financial tools such as Tally, QuickBooks, or Microsoft Excel.",
  "Familiarity with Ghanaian tax laws and financial compliance standards.",
  "Experience working with local businesses or organizations in budgeting and financial reporting.",
  "Strong analytical skills and attention to detail.",
  "Excellent verbal and written communication skills in English, with knowledge of local languages being a plus.",
  "Prior experience in a finance-related role in Ghana is an advantage.",
];

const ApplicationDetails = () => {
  const details = [
    { label: "Years of Experience", value: "3 years" },
    { label: "Salary Expectations", value: "$2000" },
    { label: "Close Date", value: "12/10/2024" },
    { label: "Career Level", value: "Mid-Level" },
  ];

  return (
    <div className="px-5 pb-16 font-outfit">
      <div className="py-4 my-2 mb-5 flex flex-col items-center justify-center px-4">
        <div className="flex flex-col items-center justify-center">
          <ApplicationsProfileImage />
        </div>
        <div className="w-full mt-6">
          <div className="flex justify-between items-end">
            <div className="flex flex-col justify-center items-center w-full">
              <div className="flex items-center gap-2">
                <h2 className="text-xl font-bold">Developer</h2>
                <Badge type="success" label="Open" />
              </div>
              <LinkButton label="Google" />
              <p className="font-light text-gray-500 text-sm my-1">Full time</p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row text-sm w-full mt-6 gap-4 items-center justify-center">
            <div className="flex items-center gap-2 lg:border-r lg:pr-4">
              <GhanaFlag />
              <span className="text-gray-600 text-sm">Ghana</span>
            </div>
            <div className="flex flex-col lg:border-r lg:px-4">
              <p className="text-gray-400">Number of openings</p>
              <span className="text-gray-600 text-sm">2</span>
            </div>
            <div className="flex items-center gap-2">
              <img
                src={SmallAvatar}
                alt="Assigned Recruiter"
                className="w-8 h-8 rounded-full"
              />
              <div className="flex flex-col">
                <span className="text-gray-400 text-sm">
                  Assigned Recruiter
                </span>
                <span className="text-gray-600 text-sm">Gift Avi</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center gap-2 text-sm border-t border-b p-3">
        {details.map((detail, index) => (
          <div
            key={index}
            className={`flex flex-col px-2 ${
              index === details.length - 1 ? "" : "border-r"
            }`}
          >
            <span className="text-[#7C8DB5]">{detail.label}</span>
            <span className="text-[#82868C]">{detail.value}</span>
          </div>
        ))}
      </div>
      <div className="flex border-b px-6 py-8 gap-4">
        <span className="bg-[#EBEDF0] h-11 items-center py-3 text-xs px-4 rounded-xl text-[#8E939C]">
          Financial Analysis
        </span>
        <span className="bg-[#EBEDF0] h-11 items-center py-3 px-4 text-sm rounded-xl text-[#8E939C]">
          Budget Management
        </span>
      </div>
      <div className="flex flex-col border-b pb-6">
        <span className="text-[#969EAE] py-6 text-sm">Job Descriptions:</span>
        <p className="text-[#232325] text-sm">
          As a Finance Support professional, you will provide essential
          assistance in managing financial operations, ensuring accuracy and
          compliance with policies. Responsibilities include processing
          invoices, tracking budgets, preparing financial reports, and
          supporting audits. This role demands strong analytical skills and the
          ability to collaborate with cross-functional teams to maintain
          efficient financial processes.
        </p>
      </div>
      <div className="flex flex-col border-b pb-10 mb-10">
        <span className="text-[#969EAE] py-6 text-sm">Job Requirements:</span>
        <ul className="list-disc space-y-4 text-[#232325] text-sm ml-6">
          {requirements.map((requirement, index) => (
            <li key={index}>{requirement}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ApplicationDetails;
