import TasksStats from "@components/stats/tasksStats";
import TasksTab from "@features/candidates/TasksTab";

function TasksPage() {
  return (
    <div>
      <div className="bg-[#DDEFFF33] rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-2/3">
            <TasksStats />
          </div>
        </div>
      </div>
      <TasksTab />
    </div>
  );
}

export default TasksPage;
