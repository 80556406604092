import { INTERVIEW_STAGES } from "@components/utils";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

interface ApplicationStageFileterProps {
  currentStage: string;
  onClick: (stage: any) => void;
}

const ApplicationsStageFilter = ({
  currentStage = "Interview Stage 1",
  onClick,
}: ApplicationStageFileterProps) => {
  const stages = INTERVIEW_STAGES;

  const currentStageIndex = stages.findIndex(
    (stage) => stage.value === currentStage
  );
  return (
    <div className="flex flex-col justify-start space-y-4 mt-4 md:flex-row md:space-y-0 md:space-x-4">
      {stages.map((stage, index) => (
        <div key={stage.value} className="flex items-center">
          <button
            onClick={() => onClick(stage.value)}
            className={`px-2 py-2 text-sm transition-colors duration-300 lg:px-4 ${
              index === currentStageIndex ? "text-[#407BFF]" : "text-[#BEC2C8]"
            }`}
          >
            {stage.label}
          </button>
          {index < stages.length - 1 && (
            <ChevronRightIcon className="h-4 w-4 text-[#BEC2C8]" />
          )}
        </div>
      ))}
    </div>
  );
};

export default ApplicationsStageFilter;
