type Props = {
  onClick: () => void;
  label: string;
  props?: any;
  disabled?: boolean;
};

const AddButton = ({ onClick, label, disabled, props }: Props) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className="bg-custom_deep_blue text-sm text-white px-4 py-2 rounded-lg font-outfit flex items-center gap-3 disabled:bg-[#969EAE] disabled:opacity-50"
      {...props}
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5 8.5H8.5V14.5H6.5V8.5H0.5V6.5H6.5V0.5H8.5V6.5H14.5V8.5Z"
          fill="white"
        />
      </svg>
      <span>{label}</span>
    </button>
  );
};

export default AddButton;
