import AuthLayout from "@pages/auth/layout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "@pages/auth/login/page";
import ForgotPassword from "@pages/auth/forgotpassword/page";
import DashboardLayout from "@pages/dashboard/layout";
import DashbordPage from "@pages/dashboard/dashboard/page";
import AddNewTask from "@pages/dashboard/dashboard/addtask/page";
import UpdateTask from "@pages/dashboard/dashboard/updatetask/[id]/page";
import Employee from "@pages/dashboard/hr/employees/page";
import AddNewEmployee from "@pages/dashboard/hr/employees/addemployee/page";
import EmployeeProfile from "@pages/dashboard/hr/employees/viewemployee/[id]/page";
import EmployeeTasks from "@pages/dashboard/hr/employees/viewemployee/[id]/tasks/page";
import Candidates from "@pages/dashboard/hr/candidates/page";
import AddNewCandidate from "@pages/dashboard/hr/candidates/addcandidate/page";
import ViewEmployee from "@pages/dashboard/hr/employees/viewemployee/[id]/layout";
import CandidateLayout from "@pages/dashboard/hr/candidates/viewcandidate/[id]/layout";
import CandidatePool from "@pages/dashboard/hr/candidate_pool/page";
import CandidatePoolLayout from "@pages/dashboard/hr/candidate_pool/layout";
import SingleCandidatePool from "@pages/dashboard/hr/candidate_pool/[department]/page";
import Teams from "@pages/dashboard/hr/teams/page";
import SingleTeam from "@pages/dashboard/hr/teams/[team]/page";
import Companies from "@pages/dashboard/crm/companies/page";
import ViewCompany from "@pages/dashboard/crm/companies/view/[id]/page";
import AddCompany from "@pages/dashboard/crm/companies/add/page";
import Blogs from "@pages/dashboard/cms/blogs/page";
import ViewBlog from "@pages/dashboard/viewblog";
import Products from "@pages/dashboard/settings/products/page";
import EditProduct from "@pages/dashboard/admin/testimonials/editproduct/page";
import TestimonialsPage from "@pages/dashboard/cms/testimonials/page";
import EmployeeTimeReports from "@pages/dashboard/hr/employees/viewemployee/[id]/timereports/page";
import EmployeeNotes from "@pages/dashboard/hr/employees/viewemployee/[id]/notes/page";
import LMS from "@pages/dashboard/hr/employees/viewemployee/[id]/lms/page";
import TokenPage from "@pages/TokenPage";
import Leave from "@pages/dashboard/hr/leave/layout";
import CurrentPosition from "@pages/dashboard/area/current_positions/page";

// import LeaveLayout from "@pages/dashboard/hr/leave/layout";
// import LeaveTypeLayout from "@pages/dashboard/hr/leave/tabs/layout";
// import Holidays from "@pages/dashboard/hr/leave/tabs/holidays/page";
// import Sickness from "@pages/dashboard/hr/leave/tabs/sickness/page";
// import Requests from "@pages/dashboard/hr/leave/tabs/requests/page";
// import Today from "@pages/dashboard/hr/leave/tabs/today/page";

import AdditionalInformation from "@pages/dashboard/hr/employees/viewemployee/[id]/additionalinformation/layout";
import PersonalInformation from "@pages/dashboard/hr/employees/viewemployee/[id]/additionalinformation/personalinformation/personalinformation";
import Document from "@pages/dashboard/hr/employees/viewemployee/[id]/additionalinformation/document";
import EndEmployment from "@pages/dashboard/hr/employees/viewemployee/[id]/additionalinformation/endemployment";
import Dependants from "@pages/dashboard/hr/employees/viewemployee/[id]/additionalinformation/dependants";
import LeaveTypeLayout from "@pages/dashboard/hr/leave/tabs/layout";
import EmergencyContact from "@pages/dashboard/hr/employees/viewemployee/[id]/additionalinformation/emergency";
import ReportNotes from "@pages/dashboard/reports/notes/page";
import TimeReportsLayout from "@pages/dashboard/reports/time_reports/page";
// import AddHoliday from "@pages/dashboard/hr/leave/tabs/holidays/addholiday/page";
// import RequestSickness from "@pages/dashboard/hr/leave/tabs/sickness/requestsickness/page";
// import RequestTimeOff from "@pages/dashboard/hr/leave/tabs/requests/requesttimeoff/page";
import LMSPAGE from "@pages/dashboard/lms/index";
import AddCoursePage from "@pages/dashboard/lms/addcourse";
import ContactsPage from "@pages/dashboard/crm/contacts/page";
import NotificationsPage from "@pages/dashboard/notifications/page";
import LeadsPage from "@pages/dashboard/crm/leads/page";
import ViewLead from "@pages/dashboard/crm/leads/view/page";
import ViewCurrentPosition from "@pages/dashboard/area/current_positions/view/page";
import CurrentPositionTab from "@pages/dashboard/area/current_positions/view/currentposition/page";
import JobsPage from "@pages/dashboard/workspace/page";
import JobDetailPage from "@pages/dashboard/workspace/jobs/view/[id]/page";
import ApplicationsPage from "@pages/dashboard/workspace/jobs/applications/page";
import NotesTab from "@features/candidates/NotesTab";
import TasksTab from "@pages/dashboard/hr/employees/viewemployee/[id]/tasks/page";
import JobApplicationCandidate from "@pages/dashboard/workspace/jobs/components/jobApplicationCandidate";
import TasksPage from "@pages/dashboard/area/tasks/page";

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  // const token = sessionStorage.getItem("access_token");
  // const token = "token";
  // const path = window.location.pathname;

  // if (token && path === "/login") {
  //   return <Navigate to="/" replace />;
  // }

  // if (!token && path !== "/login") {
  //   return <Navigate to="/login" replace />;
  // }

  return <>{children}</>;
};

const router = createBrowserRouter([
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    path: "/",
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        index: false,
        path: "/forgotpassword",
        element: <ForgotPassword />,
      },
    ],
  },
  {
    index: true,
    path: "auth/login",
    element: <TokenPage />,
  },
  {
    element: (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    path: "/",
    children: [
      {
        index: true,
        path: "/",
        element: <DashbordPage />,
      },
      {
        index: false,
        path: "/addtask",
        element: <AddNewTask />,
      },
      {
        index: false,
        path: "/updatetask/:id",
        element: <UpdateTask />,
      },
      {
        index: false,
        path: "/hr/employees",
        element: <Employee />,
      },
      {
        index: false,
        path: "/hr/employees/addemployee",
        element: <AddNewEmployee />,
      },
      {
        index: false,
        path: "/hr/employees/viewemployee/:id",
        element: <ViewEmployee />,
        children: [
          {
            index: true,
            path: "/hr/employees/viewemployee/:id",
            element: <EmployeeProfile />,
          },
          {
            index: true,
            path: "/hr/employees/viewemployee/:id/tasks",
            element: <EmployeeTasks />,
          },
          {
            index: true,
            path: "/hr/employees/viewemployee/:id/timereports",
            element: <EmployeeTimeReports />,
          },
          {
            index: true,
            path: "/hr/employees/viewemployee/:id/notes",
            element: <EmployeeNotes />,
          },
          {
            index: true,
            path: "/hr/employees/viewemployee/:id/lms",
            element: <LMS />,
          },
          {
            index: false,
            path: "/hr/employees/viewemployee/:id/additionalinformation",
            element: <AdditionalInformation />,
            children: [
              {
                index: true,
                path: "/hr/employees/viewemployee/:id/additionalinformation",
                element: <PersonalInformation />,
              },
              {
                index: false,
                path: "/hr/employees/viewemployee/:id/additionalinformation/documents",
                element: <Document />,
              },
              {
                index: false,
                path: "/hr/employees/viewemployee/:id/additionalinformation/endemployment",
                element: <EndEmployment />,
              },
              {
                index: false,
                path: "/hr/employees/viewemployee/:id/additionalinformation/dependants",
                element: <Dependants />,
              },
              {
                index: false,
                path: "/hr/employees/viewemployee/:id/additionalinformation/emergencycontact",
                element: <EmergencyContact />,
              },
            ],
          },
        ],
      },
      {
        index: false,
        path: "/hr/candidates",
        element: <Candidates />,
      },
      {
        index: false,
        path: "/hr/candidates/addcandidate",
        element: <AddNewCandidate />,
      },
      {
        index: false,
        path: "/hr/candidates/viewcandidate/:id",
        element: <CandidateLayout />,
      },
      {
        element: <CandidatePoolLayout />,
        path: "/hr/candidate_pool",

        children: [
          {
            index: true,
            path: "/hr/candidate_pool",
            element: <CandidatePool />,
          },
          {
            index: true,
            path: "/hr/candidate_pool/:department",
            element: <SingleCandidatePool />,
          },
        ],
      },

      {
        index: false,
        path: "/area/current_positions",
        element: <CurrentPosition />,
      },
      {
        index: false,
        path: "/area/current_positions/:id",
        element: <ViewCurrentPosition />,
        children: [
          {
            index: true,
            path: "/area/current_positions/:id",
            element: <CurrentPositionTab />,
          },
          {
            index: true,
            path: "/area/current_positions/:id/tasks",
            element: <TasksTab />,
          },
          {
            index: true,
            path: "/area/current_positions/:id/notes",
            element: <NotesTab />,
          },
        ],
      },

      {
        index: false,
        path: "/hr/teams",
        element: <Teams />,
      },
      {
        index: false,
        path: "/hr/leave",
        element: <Leave />,
      },
      {
        index: false,
        path: "/hr/leave/details",
        element: <LeaveTypeLayout />,
      },
      {
        index: false,
        path: "/hr/teams/:team",
        element: <SingleTeam />,
      },

      {
        index: false,
        path: "/crm/companies",
        element: <Companies />,
      },
      {
        index: false,
        path: "/crm/companies/view/:id",
        element: <ViewCompany />,
      },
      {
        index: false,
        path: "/crm/companies/add",
        element: <AddCompany />,
      },
      {
        index: false,
        path: "/crm/contacts",
        element: <ContactsPage />,
      },

      {
        index: false,
        path: "/cms/blogs",
        element: <Blogs />,
      },
      {
        index: false,
        path: "/cms/testimonials",
        element: <TestimonialsPage />,
      },

      {
        index: false,
        path: "/settings/products",
        element: <Products />,
      },

      {
        index: false,
        path: "/settings/products/editproduct",
        element: <EditProduct />,
      },
      {
        index: false,
        path: "/reports/notes",
        element: <ReportNotes />,
      },
      {
        index: false,
        path: "/reports/time_reports",
        element: <TimeReportsLayout />,
      },
      {
        path: "/lms",
        element: <LMSPAGE />,
      },
      {
        path: "/lms/addcourse",
        element: <AddCoursePage />,
      },
      {
        index: false,
        path: "/notifications",
        element: <NotificationsPage />,
      },
      {
        index: false,
        path: "/crm/leads",
        element: <LeadsPage />,
      },
      {
        index: false,
        path: "/crm/leads/view/:id",
        element: <ViewLead />,
      },
      {
        index: false,
        path: "/workspace/jobs",
        element: <JobsPage />,
      },
      {
        index: false,
        path: "/workspace/jobs/view/:id",
        element: <JobDetailPage />,
      },
      {
        index: false,
        path: "/workspace/jobs/job_applications/:job",
        element: <ApplicationsPage />,
      },
      {
        index: false,
        path: "/workspace/jobs/job_applications/:job/:candidateId",
        element: <JobApplicationCandidate />,
      },
      {
        index: false,
        path: "/area/tasks",
        element: <TasksPage />,
      },
    ],
  },
  {
    index: false,
    path: "/cms/blog/:id",
    element: <ViewBlog />,
  },
]);

function index() {
  return <RouterProvider router={router} />;
}
export default index;
