import { INTERVIEW_STAGES } from "@components/utils";

interface ApplicationStageProps {
  currentStage: string;
}

const ApplicationStage = ({
  currentStage = "Interview Stage 1",
}: ApplicationStageProps) => {
  const stages = INTERVIEW_STAGES;

  const currentStageIndex = stages.findIndex(
    (stage) => stage.value === currentStage
  );

  return (
    <div className="flex flex-col justify-start space-y-4 mt-4 md:flex-row md:space-y-0 md:space-x-4">
      {stages.map((stage, index) => (
        <div key={stage.value} className="flex items-center">
          <div
            className={`px-2 py-2 text-sm transition-colors duration-300 lg:px-4 ${
              index <= currentStageIndex ? "text-[#407BFF]" : "text-[#BEC2C8]"
            }`}
          >
            {stage.label}
          </div>
          {index < stages.length - 1 && (
            <div className="h-4 w-px bg-[#BEC2C8] md:h-px md:w-4"></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ApplicationStage;
