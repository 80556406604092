import Graphs from "./graphs/graphs";
import TimeReport from "./timereport/page";

function CurrentPosition() {
  return (
    <div className="mt-6">
      <Graphs />

      <TimeReport />
    </div>
  );
}

export default CurrentPosition;
