import React from "react";
import tasks from "./data.json";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import PencilIcon from "@components/svgs/pencil";
import DeleteIcon from "@components/svgs/delete";
import Delete from "@pages/dashboard/cms/blogs/delete";
import EditPosition from "../addposition/editposition";
import {TableColumn} from "@components/DataTable/types";
import LinkButton from "@components/ButtonV2/LinkButton";
import Table from "@components/DataTable";

function TaskList({handleViewPosition}: {handleViewPosition: any}) {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(3);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [selected, setSelected] = React.useState<any>(null);

  const handleNext = () => {
    setStart(start + 3);
    setEnd(end + 3);
  };

  const handlePrev = () => {
    setStart(start - 3);
    setEnd(end - 3);
  };

  const toggleDelete = () => {
    setShowDelete(!showDelete);
  };

  const toggleEdit = () => {
    setShowEdit(!showEdit);
  };

  const handleDelete = (task: any) => {
    setSelected(task);
    toggleDelete();
  };

  const handleEdit = (task: any) => {
    setSelected(task);
    toggleEdit();
  };

  const columns: TableColumn<any>[] = [
    {
      key: "id",
      label: "ID",
      sortable: true,
    },
    {
      key: "user",
      label: "Name",
      sortable: true,
      render: (item) => (
        <LinkButton label={item} onClick={() => handleViewPosition(item)} />
      ),
    },
    {
      key: "company",
      label: "Company",
      sortable: true,
    },
    {
      key: "action",
      label: "Action",
      sortable: false,
      render: (item) => (
        <span className="flex align-middle gap-x-2">
          <button onClick={() => handleEdit(item)}>
            <PencilIcon />
          </button>
          <button onClick={() => handleDelete(item)}>
            <DeleteIcon />
          </button>
        </span>
      ),
    },
  ];

  return (
    <div className="px-5">
      <div className="-mx-4 sm:-mx-0">
        <Table columns={columns} data={tasks?.slice(start, end)} />
        {/* <table className="min-w-full divide-y divide-gray-100">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                ID
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Name
              </th>
              <th
                scope="col"
                className="hidden  py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Company
              </th>
              <th
                scope="col"
                className="hidden  py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100 bg-white">
            {tasks?.slice(start, end).map((task) => (
              <Fragment key={task?.id}>
                <tr key={task.id} className="cursor-pointer hover:bg-gray-50">
                  <td className="whitespace-nowrap  py-4 pl-4 pr-3 text-sm font-medium sm:pl-0">
                    {task?.id}
                  </td>
                  <td
                    onClick={() => handleViewPosition(task)}
                    className="whitespace-nowrap underline text-custom_blue py-4 pl-4 pr-3 text-sm font-medium sm:pl-0"
                  >
                    {task?.user}
                  </td>
                  <td className="whitespace-nowrap py-4 uppercase pl-4 pr-3 text-sm  sm:pl-0">
                    {task?.company}
                  </td>
                  <td className="whitespace-nowrap py-4 uppercase pl-4 pr-3 text-sm  sm:pl-0 flex gap-x-2">
                    <button onClick={handleEdit}>
                      <PencilIcon />
                    </button>
                    <button onClick={() => handleDelete(task)}>
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table> */}
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start + 1}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{tasks?.length}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              disabled={start === 0}
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              1
            </a>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>

      <EditPosition isOpen={showEdit} onClose={toggleEdit} />
      <Delete
        open={showDelete}
        setOpen={setShowDelete}
        selected={{title: selected?.company, class: "position"}}
        buttonTitle="Delete"
      />
    </div>
  );
}

export default TaskList;
