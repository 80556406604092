import CustomDatePicker from "@components/datepicker";
import Dropdown from "@components/dropdowns/dropdown";
import TextInput from "@components/textInput";
import { useState } from "react";
import MyEditor from "@components/editor";

interface JobItem {
  country: string;
  careerLevel: string;
  yearsOfExperience: string;
  salary: string;
  skills: string;
  jobType: string;
  closeDate: string;
  assignRecruiter: string;
  role: string;
  jobDescription: string;
  jobRequirements: string;
}

const AddJobItem = () => {
  const [jobDetails, setJobDetails] = useState<JobItem>({
    role: "",
    jobType: "",
    careerLevel: "",
    country: "",
    yearsOfExperience: "",
    salary: "",
    closeDate: "",
    skills: "",
    assignRecruiter: "",
    jobDescription: "",
    jobRequirements: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setJobDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSelect = (name: string, value: string) => {
    setJobDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="gap-y-4 flex flex-col pb-16 my-8 px-5">
        <Dropdown
          key={"role"}
          label="Role:"
          selected={jobDetails.role}
          setSelected={(value) => handleSelect("role", value)}
          placeholder=""
          list={[
            { id: 1, name: "Company 1" },
            { id: 2, name: "Company 2" },
            { id: 3, name: "Company 3" },
          ]}
        />
        <Dropdown
          key={"jobType"}
          label="Job Type:"
          selected={jobDetails.jobType}
          setSelected={(value) => handleSelect("jobType", value)}
          placeholder=""
          list={[
            { id: 1, name: "Company 1" },
            { id: 2, name: "Company 2" },
            { id: 3, name: "Company 3" },
          ]}
        />
        <Dropdown
          key={"careerLevel"}
          label="Career Level:"
          selected={jobDetails.careerLevel}
          setSelected={(value) => handleSelect("careerLevel", value)}
          placeholder=""
          list={[
            { id: 1, name: "Company 1" },
            { id: 2, name: "Company 2" },
            { id: 3, name: "Company 3" },
          ]}
        />
        <Dropdown
          key={"country"}
          label="Country:"
          selected={jobDetails.country}
          setSelected={(value) => handleSelect("country", value)}
          placeholder=""
          list={[
            { id: 1, name: "Company 1" },
            { id: 2, name: "Company 2" },
            { id: 3, name: "Company 3" },
          ]}
        />
        <TextInput
          name="yearsOfExperience"
          label="Years of experience:"
          value={jobDetails.yearsOfExperience}
          onChange={handleChange}
        />
        <TextInput
          name="salary"
          label="Salary:"
          value={jobDetails.salary}
          onChange={handleChange}
        />
        <CustomDatePicker
          label="Close Date:"
          selectedDate={jobDetails.closeDate}
          setSelectedDate={(date) => handleSelect("closeDate", date)}
          type="date"
        />
        <TextInput
          name="skills"
          label="Skills:"
          value={jobDetails.skills}
          onChange={handleChange}
        />
        <Dropdown
          key={"assignRecruiter"}
          label="Assign Recruiter:"
          selected={jobDetails.assignRecruiter}
          setSelected={(value) => handleSelect("assignRecruiter", value)}
          placeholder=""
          list={[
            { id: 1, name: "Recruiter 1" },
            { id: 2, name: "Recruiter 2" },
            { id: 3, name: "Recruiter 3" },
          ]}
        />
        <TextInput
          name="role"
          label="Role:"
          value={jobDetails.role}
          onChange={handleChange}
        />
        <div>
          <MyEditor
            value={jobDetails.jobDescription}
            label="Job Description:"
            setValue={(value) => handleSelect("jobDescription", value)}
            placeholder="Write notes here..."
            height="60"
            className="h-60"
          />
        </div>
        <div>
          <MyEditor
            value={jobDetails.jobRequirements}
            label="Job Requirements:"
            setValue={(value) => handleSelect("jobRequirements", value)}
            placeholder="Write notes here..."
            height="60"
            className="h-60"
          />
        </div>
        <div className="lg:col-span-4 mt-4 col-span-10 flex justify-end items-end">
          <button className="bg-[#1F3C49] rounded-lg font-light py-3 px-4 flex">
            <span className="text-white">Add Job</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default AddJobItem;
