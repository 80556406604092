const RatingStars = (props: any) => {
  return (
    <svg
      width="147"
      height="24"
      viewBox="0 0 147 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9651 0L14.6515 8.2918H23.3446L16.3117 13.4164L18.998 21.7082L11.9651 16.5836L4.9322 21.7082L7.61853 13.4164L0.585614 8.2918H9.27878L11.9651 0Z"
        fill="#FFC527"
      />
      <path
        d="M42.7327 0L45.419 8.2918H54.1122L47.0793 13.4164L49.7656 21.7082L42.7327 16.5836L35.6998 21.7082L38.3861 13.4164L31.3532 8.2918H40.0464L42.7327 0Z"
        fill="#FFC527"
      />
      <path
        d="M73.5003 0L76.1866 8.2918H84.8798L77.8469 13.4164L80.5332 21.7082L73.5003 16.5836L66.4674 21.7082L69.1537 13.4164L62.1208 8.2918H70.8139L73.5003 0Z"
        fill="#FFC527"
      />
      <path
        d="M104.268 0L106.954 8.2918H115.647L108.614 13.4164L111.301 21.7082L104.268 16.5836L97.2349 21.7082L99.9213 13.4164L92.8883 8.2918H101.582L104.268 0Z"
        fill="#FFC527"
      />
      <path
        d="M135.035 0L137.722 8.2918H146.415L139.382 13.4164L142.068 21.7082L135.035 16.5836L128.003 21.7082L130.689 13.4164L123.656 8.2918H132.349L135.035 0Z"
        fill="#2B2B2B"
        fill-opacity="0.1"
      />
    </svg>
  );
};

export default RatingStars;
