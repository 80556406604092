const TasksAssigned = (props: any) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_9483_49242)">
        <path
          d="M19.5273 23.1657H4.47398C4.32515 23.1665 4.1776 23.1381 4.03977 23.0819C3.90193 23.0258 3.7765 22.9431 3.67064 22.8384C3.56478 22.7338 3.48056 22.6094 3.4228 22.4722C3.36504 22.335 3.33486 22.1878 3.33398 22.039V4.95899C3.33794 4.79408 3.37683 4.63188 3.44807 4.48309C3.51932 4.33431 3.62131 4.20233 3.74732 4.09586C3.87332 3.98938 4.02047 3.91084 4.17905 3.86542C4.33764 3.81999 4.50405 3.80871 4.66732 3.83233H6.00065V5.16566H4.66732V21.8323H19.334V5.16566H18.0007V3.83233H19.5007C19.6517 3.82788 19.8021 3.85369 19.9431 3.90823C20.084 3.96277 20.2126 4.04495 20.3213 4.14993C20.43 4.25491 20.5166 4.38056 20.576 4.51951C20.6355 4.65845 20.6665 4.80788 20.6673 4.95899V22.039C20.6664 22.1878 20.6363 22.335 20.5785 22.4722C20.5207 22.6094 20.4365 22.7338 20.3307 22.8384C20.2248 22.9431 20.0994 23.0258 19.9615 23.0819C19.8237 23.1381 19.6762 23.1665 19.5273 23.1657Z"
          fill="#AC7E03"
        />
        <path
          d="M11.1057 17.6753L7.53238 14.102C7.42401 13.9737 7.36805 13.8092 7.37569 13.6414C7.38334 13.4737 7.45402 13.3149 7.57362 13.197C7.69321 13.0791 7.85291 13.0107 8.02077 13.0054C8.18863 13.0002 8.3523 13.0585 8.47905 13.1686L11.1057 15.7953L16.8657 10.0353C16.9932 9.92609 17.1573 9.86902 17.3251 9.8755C17.4929 9.88198 17.652 9.95153 17.7708 10.0703C17.8895 10.189 17.959 10.3482 17.9655 10.5159C17.972 10.6837 17.9149 10.8478 17.8057 10.9753L11.1057 17.6753Z"
          fill="#AC7E03"
        />
        <path
          d="M17.3327 7.83335H6.66602V5.38669C6.66777 4.97526 6.83199 4.58118 7.12291 4.29025C7.41384 3.99933 7.80792 3.83511 8.21935 3.83335H9.41268C9.55955 3.25974 9.89315 2.75133 10.3609 2.38826C10.8286 2.02519 11.4039 1.82812 11.996 1.82812C12.5881 1.82812 13.1634 2.02519 13.6311 2.38826C14.0989 2.75133 14.4325 3.25974 14.5793 3.83335H15.7727C16.1853 3.83335 16.5811 3.99679 16.8734 4.28791C17.1658 4.57902 17.3309 4.97411 17.3327 5.38669V7.83335ZM7.99935 6.50002H15.9993V5.38669C15.9993 5.32834 15.9762 5.27238 15.9349 5.23112C15.8937 5.18987 15.8377 5.16669 15.7793 5.16669H13.3327V4.50002C13.3327 4.1464 13.1922 3.80726 12.9422 3.55721C12.6921 3.30716 12.353 3.16669 11.9993 3.16669C11.6457 3.16669 11.3066 3.30716 11.0565 3.55721C10.8065 3.80726 10.666 4.1464 10.666 4.50002V5.16669H8.21935C8.19046 5.16669 8.16185 5.17238 8.13516 5.18343C8.10847 5.19449 8.08421 5.21069 8.06379 5.23112C8.04336 5.25155 8.02715 5.2758 8.0161 5.3025C8.00504 5.32919 7.99935 5.3578 7.99935 5.38669V6.50002Z"
          fill="#AC7E03"
        />
      </g>
      <defs>
        <clipPath id="clip0_9483_49242">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TasksAssigned;
