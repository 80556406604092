import Badge from "@components/badges/Badge";
import JobStats from "@components/stats/jobStats";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import FacebookIcon from "@components/svgs/facebook";
import LinkedIn from "@components/svgs/linkedin";
import Instagram from "@components/svgs/instagram";
import { useNavigate } from "react-router-dom";
import Avartar from "@assets/candidate.jpg";
import AddButton from "@components/ButtonV2/AddButton";
import TabsV2 from "@components/tabs/TabV2";
import NotesTab from "@features/candidates/NotesTab";
import TasksTab from "@features/candidates/TasksTab";
import CandidateProfileTab from "@components/CandidateProfileTab";
import Activities from "@pages/dashboard/crm/companies/view/[id]/components/acticities/page";
import ApplicationStage from "./applicationStage";
import RatingStars from "@components/svgs/rating";

const tabsData = [
  {
    id: "candidate-profile",
    label: "Candidate profile",
    component: <CandidateProfileTab />,
  },
  {
    id: "notes",
    label: "Notes",
    component: <NotesTab />,
  },
  {
    id: "tasks",
    label: "Tasks",
    component: <TasksTab />,
  },
  {
    id: "last_activities",
    label: "Last Activities",
    component: <Activities />,
  },
];

const JobApplicationCandidate = () => {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <div className="bg-[#DDEFFF33] rounded-lg font-inter">
        <div className="flex justify-between items-center">
          <div className="w-2/3">
            <JobStats />
          </div>
        </div>
      </div>
      <div className="flex mt-2 justify-between">
        <button
          onClick={() => navigate(-1)}
          className="text-custom_secondary hover:underline flex gap-x-3 items-center font-outfit font-light"
        >
          <ChevronLeftIcon className="h-6 w-6 text-custom_secondary" />
          Back
        </button>
      </div>
      <div className="bg-[rgba(221,239,255,0.27)] p-6 mt-6 rounded-lg">
        <div className="py-8 bg-custom_faded_blue flex flex-col lg:flex-row px-4">
          <div className="flex flex-col items-center justify-center">
            <img
              src={Avartar}
              className=" h-auto w-80 rounded-full"
              alt="Picture of the Candidate"
            />

            <div className="flex gap-x-3 items-center mt-2">
              <LinkedIn />
              <Instagram />
              <FacebookIcon />
            </div>
          </div>
          <div className="lg:ms-12 mt-8 lg:mt-0">
            <div className="flex justify-between">
              <div className="flex">
                <div>
                  <h2 className="text-xl text-[#232325] font-bold">
                    Immaculate Akuvi
                  </h2>
                  <p className="font-light text-sm font-outfit">UI/UX Design</p>
                  <div className="mt-2">
                    <RatingStars />
                  </div>
                </div>
                <div className="ms-6">
                  <Badge type="neutral" label="Interview stage" />
                </div>
              </div>
            </div>
            <div className="mt-3 flex justify-between">
              <p className="text-[#82868C] text-sm w-2/3 font-outfit">
                Highly skilled software quality assurance engineer with over 4
                years experience in testing and ensuring the quality of software
                applications. consistently met project deadlines and exceeded
                client expectations by implementing effective testing
                strategies. seeking a challenging role where i can leverage my
                expertise to contribute to the success of a dynamic
                organization.
              </p>
              <div>
                {" "}
                <AddButton disabled onClick={() => {}} label="Make Offer" />
              </div>
            </div>
          </div>
        </div>
        <ApplicationStage currentStage="interview_stage_1" />
      </div>
      <div className="w-full bg-[#DDEFFF33] rounded-lg mt-8 flex-1 flex flex-col px-6">
        <TabsV2 tabs={tabsData} />
      </div>
    </div>
  );
};

export default JobApplicationCandidate;
