import ButtonV2 from "@components/ButtonV2/ButtonV2";
import FilePickerImage from "@components/filepickerImage";
import Accordion from "@components/accordion/accordion";
import PoolPopup from "@pages/dashboard/hr/employees/viewemployee/PoolPopup";
import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface Pool {
  id: number;
  name: string;
  color: string;
}

const CandidateProfileTab = () => {
  const [selectedPools, setSelectedPools] = useState([
    { id: 1, name: "Talent pool", color: "bg-custom_deep_purple" },
  ]);

  const addToPool = (selected: Pool) => {
    setSelectedPools([...selectedPools, selected]);
  };

  const handleRemovePool = (id: number) => {
    const newPools = selectedPools.filter((pool) => pool.id !== id);
    setSelectedPools(newPools);
  };
  return (
    <div className="gap-5 flex lg:flex-row flex-col">
      <div className="lg:w-1/4 w-full py-4 px-3 rounded-lg flex flex-col gap-6 bg-custom_faded_blue">
        <div className="">
          <div className="flex mb-3 justify-between items-center">
            <label className="text-xs">Talent pool:</label>
            <PoolPopup handleCLick={addToPool} />
          </div>
          <div className="border rounded-xl gap-2 py-3 px-2 flex items-center flex-wrap ">
            {selectedPools.map((pool) => (
              <div className="flex rounded-md border bg-white py-2 px-3 items-center gap-x-2">
                <div className={`h-4 w-4 rounded-full ${pool?.color}`} />{" "}
                <span className="text-black text-xs">{pool?.name}</span>
                <XMarkIcon
                  onClick={() => handleRemovePool(pool.id)}
                  className="h-3 w-3 cursor-pointer"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="">
          <button className="inline-flex h-12 justify-center py-3 w-full rounded-lg bg-transparent border border-[#27CE88] px-4 text-xs  focus:outline-none  focus-visible:ring-white/75 items-center">
            Portfolio
          </button>
        </div>
        <ButtonV2 text="CV" />
        <ButtonV2 text="Remotelli CV" />

        <div className="">
          <FilePickerImage />
        </div>

        <div>
          <ButtonV2 variant="tertiary" text="Update" onClick={() => null} />
        </div>
      </div>

      <div className="lg:w-3/4 w-full  rounded-lg flex flex-col gap-6 ">
        <div className="bg-custom_faded_blue py-4 px-3">
          <Accordion />
        </div>
      </div>
    </div>
  );
};

export default CandidateProfileTab;
