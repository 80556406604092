import React from "react";
import tasks from "./data.json";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import moment from "moment";
import Table from "@components/DataTable";
import {TableColumn} from "@components/DataTable/types";

function CandidateList() {
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(2);

  const handleNext = () => {
    setStart(start + 2);
    setEnd(end + 2);
  };

  const handlePrev = () => {
    setStart(start - 2);
    setEnd(end - 2);
  };

  const columns: TableColumn<any>[] = [
    {key: "id", label: "ID", sortable: true},
    {
      key: "skills",
      label: "Task",
      sortable: true,
    },
    {
      key: "",
      label: "Company",
      sortable: false,
      render: () => <span>Solutions for Pros</span>,
    },
    {
      key: "name",
      label: "User",
      sortable: true,
    },

    {
      key: "",
      label: "Info",
      sortable: true,
      render: () => <span>United Kingdom</span>,
    },
    {
      key: "",
      label: "Start date",
      sortable: true,
      render: () => <span>{moment()?.format("DD/MM/YYYY")}</span>,
    },
    {
      key: "",
      label: "End date",
      sortable: true,
      render: () => <span>{moment()?.format("DD/MM/YYYY")}</span>,
    },
    {key: "", label: "Days", sortable: true, render: () => <span>5</span>},
    {key: "", label: "Hours", sortable: true, render: () => <span>1</span>},
    {key: "", label: "Minutes", sortable: true, render: () => <span>22</span>},
  ];

  return (
    <div className="px-5 py-3">
      <div className="-mx-4 mt-8 sm:-mx-0 overflow-scroll">
        <Table columns={columns} data={tasks?.slice(start, end)} />
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center mt-4 sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start + 1}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{tasks?.length}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex gap-x-3 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-[#94A4C4] ring-1 rounded-full ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              1
            </a>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default CandidateList;
