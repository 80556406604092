import DeleteIcon from "@components/svgs/delete";
import PencilIcon from "@components/svgs/pencil";

type ItemActionsProps = {
  onEdit?: () => void;
  onDelete?: () => void;
};

const ItemActions = ({ onEdit, onDelete }: ItemActionsProps) => {
  return (
    <span className="flex align-middle gap-2">
      <button onClick={onEdit}>
        <PencilIcon />
      </button>
      <button onClick={onDelete}>
        <DeleteIcon />
      </button>
    </span>
  );
};

export default ItemActions;
